import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Hi! <br />
        <p style={{ width: 500 }}>
          This website isn't quite ready yet. <br /><br /> If you found this website, then you already know how to contact me. My services are only open to referrals right now, sorry!
        </p>
        <br />
        - tansan
      </header>
    </div>
  );
}

export default App;
